<template>
  <b-card>
    <b-modal
        :id="`client-delete-modal-${clientData.id}`"
        centered
        cancel-variant="outline-secondary"
        ok-variant="danger"
        title="Удаление клиента"
        size="sm"
        ok-title="Удалить"
        cancel-title="Отмена"
        @ok="deleteClient"
    >
      <h5>Вы действительно хотите удалить данного клиента?</h5>
    </b-modal>
    <div class="d-flex justify-content-start">
      <AvatarUpload
        entity="client"
        :avatar-url="clientData && clientData.avatar"
        :config="avatarUploadConfig"
        style="flex: 0 0 110px"
        variant="light-success"
        avatar-size="110px"
        :disabled="isNew || !$store.getters['user/permission']('clients:update')"
        @updated="avatarUpdated"
      />
      <div
        class="d-flex
        align-items-start
        flex-column
        ml-1
        mr-2
        client-card-limit"
        style="flex: 1;"
      >
        <EditInput
          :model="clientData.name"
          :toggle-only-by-icon="false"
          placeholder="Введите название клиента"
          :readonly="!$store.getters['user/permission']('clients:update')"
          @input="updateField('name', $event)"
        >
          <h4
              :class="
              'mb-0 cursor-pointer editable-field w-100 overflow-hidden ' +
               'd-block text-ellipsis text-nowrap '
              + (errors.includes('name') ? 'invalid' : '')"
              style="text-overflow: ellipsis"
          >
            {{ clientData.name || 'Название клиента' }}
          </h4>
        </EditInput>
        <EditInput
          :model="clientData.type"
          placeholder="Введите название клиента"
          :toggle-only-by-icon="false"
          type="dropdown"
          :options="clientTypes"
          :readonly="!$store.getters['user/permission']('clients:update')"
          @input="updateField('type', $event)"
        >
          <span class="card-text editable-field">
            {{ displayClientType }}
          </span>
        </EditInput>
        <tags
          v-if="!isNew"
          :entity="clientData"
          entity-name="client"
          @input="$emit('updated')"
        />
      </div>
      <BDropdown
        v-if="!isNew && $store.getters['user/permission']('clients:delete')"
        class="client-actions-dropdown"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
            size="21"
          />
        </template>
        <BDropdownItem
          v-b-modal="`client-delete-modal-${clientData.id}`"
          class="client-actions-dropdown-item"
          @click.prevent
        >
          <FeatherIcon
            icon="Trash2Icon"
            class="mr-1 text-danger"
          />
          <span class="text-danger">
            {{ t('settings.delete') }}
          </span>
        </BDropdownItem>
      </BDropdown>
    </div>
    <div class="mt-2 mb-1">
      <div class="d-flex w-100 flex-column">
        <div class="client-card__field w-100">
          <div class="d-flex align-items-center mr-2">
            <feather-icon
              icon="UserIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Ответственный</span>
          </div>
          <EditInput
            :model="isNew ? newUserDisplayManager : displayManager"
            placeholder="Введите имя менеджера"
            :options="computedEmployees"
            :with-select="true"
            :toggle-only-by-icon="false"
            :hide-edit-icon="true"
            :only-exists="true"
            :readonly="!$store.getters['user/permission']('clients:update')"
            :no-client-filter-options="true"
            @edit="getEmployees"
            @input="updateClientManager"
          >
            {{ (isNew ? newUserDisplayManager : displayManager) || 'Выбрать отвественного' }}
          </EditInput>
        </div>

        <div class="client-card__field w-100">
          <div class="d-flex align-items-center mr-2">
            <feather-icon
              icon="StarIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Приоритет</span>
          </div>
          <div class="client-card__field-value">
            <EditInput
              :model="clientData.priority"
              :toggle-only-by-icon="false"
              type="dropdown"
              :options="priorities"
              :readonly="!$store.getters['user/permission']('clients:update')"
              @input="updateField('priority', $event)"
            >
              <span class="card-text editable-field">
                <b-badge
                  v-if="computedClientPriority"
                  class="client-priority-badge editable-field"
                  :variant="`light-${computedClientPriority.color}`"
                >
                  {{ computedClientPriority.label }}
                </b-badge>
              </span>
            </EditInput>
          </div>
        </div>

        <div class="client-card__field w-100">
          <div class="d-flex align-items-center mr-2">
            <feather-icon
              icon="MapPinIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Город</span>
          </div>
          <EditInput
            :model="clientData.city"
            placeholder="Введите город"
            :with-select="true"
            :toggle-only-by-icon="false"
            :hide-edit-icon="true"
            :allow-custom-value="false"
            :options="citiesList"
            :readonly="!$store.getters['user/permission']('clients:update')"
            @edit="onCityInput"
            @input="updateField('city', $event)"
            @update:localValue="onCityInput"
          >
            {{ clientData.city || 'Выбрать город' }}
          </EditInput>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="!isNew"
      class="d-flex align-items-center mt-2"
    >
      <div class="d-flex align-items-center mr-2">
        <b-avatar
          variant="light-primary"
          rounded
        >
          <feather-icon
            icon="TrendingUpIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h5 class="mb-0">
            {{ incomeMonthly }}
          </h5>
          <small>За месяц</small>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <b-avatar
          variant="light-success"
          rounded
        >
          <feather-icon
            icon="DollarSignIcon"
            size="18"
          />
        </b-avatar>
        <div class="ml-1">
          <h5 class="mb-0">
            {{ incomeTotal }}
          </h5>
          <small>Прибыль</small>
        </div>
      </div>
    </div> -->
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue';
import {
  computed, inject, ref, toRefs, watch,
} from '@vue/composition-api';
import AvatarUpload from '@/components/avatarUpload/AvatarUpload.vue';
import EditInput from '@/components/editInput/EditInput.vue';
import { useStore } from '@/hooks/useStore';
import { formatPrice } from '@/utils/price';
import { getRandomString } from '@/utils/stringHelpers';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import Tags from '../tags/Tags.vue';

export default {
  components: {
    BCard,
    BBadge,
    // BAvatar,
    BDropdown,
    BDropdownItem,

    AvatarUpload,
    EditInput,
    Tags,
  },
  props: {
    isNew: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { isNew } = toRefs(props);
    const { dangerToast } = useToast();
    const store = useStore();
    const clientData = inject('clientData');
    const errors = inject('errors');

    const updateField = (field, value) => {
      errors.value.splice(errors.value.indexOf(field), 1);
      clientData.value[field] = value;
    };

    let isProcessing;
    let getClient;
    if (!isNew.value) {
      isProcessing = inject('isProcessing');
      getClient = inject('getClient');
    }

    const clientTypes = [
      {
        label: 'Компания',
        value: 'company',
      },
      {
        label: 'Частное лицо',
        value: 'person',
      },
    ];

    const displayClientType = computed(
      () => clientTypes.find((type) => type.value === clientData.value.type)?.label || '',
    );

    // Client tags
    const newTag = ref('');
    const addTagToClient = async (tag) => {
      if (!tag) return;
      await store.dispatch('tags/addTag', {
        id: clientData.value.id,
        type: 'client',
        tag,
      });
    };
    const addTag = async (tag) => {
      if (isNew.value) {
        clientData.value.tags.push({
          id: getRandomString(),
          type: 'client',
          tag,
        });
        return;
      }
      await addTagToClient(tag);
      emit('updated');
    };
    const tags = ref([]);
    const getTags = async () => {
      const { result, data } = await store.dispatch('tags/getList', { type: 'client' });
      if (result) {
        tags.value = data.map((val) => ({
          label: val.tag,
          value: val.tag,
        }));
      }
    };
    const computedTags = computed(() => {
      const mappedClientTags = clientData.value.tags.map((val) => val.tag);
      return tags.value.filter((tag) => !mappedClientTags.includes(tag.value));
    });
    const removeTag = async (tagId) => {
      if (isNew.value) {
        clientData.value.tags = clientData.value.tags.filter((val) => val.id !== tagId);
        return;
      }

      const { result } = await store.dispatch('tags/removeTag', {
        id: clientData.value.id,
        type: 'client',
        tagId: String(tagId),
      });

      if (result) {
        await getClient();
      }
    };

    // Client manager
    const employees = ref([]);
    const getEmployees = async () => {
      const { result, data } = await store.dispatch('getManagersAsync');
      employees.value = data.map((val) => ({
        label: `${val.firstname} ${val.surname}`,
        value: val.id,
      }));
      if (!result) {
        //
      }
    };
    getEmployees();
    const computedEmployees = computed(
      () => employees.value.filter((employee) => employee.value !== clientData.value.manager_id),
    );
    const updateClientManager = async (managerId) => {
      const isManagerExists = employees.value.findIndex(
        (employee) => employee.value === managerId,
      ) !== -1;
      if (!isManagerExists) {
        dangerToast(
          t('settings.updateFailedMessage.title'),
          t('settings.updateFailedMessage.text'),
        );
        return;
      }

      clientData.value.manager_id = managerId;
    };
    const displayManager = computed(() => (clientData.value.manager
      ? `${clientData.value.manager.firstname} ${clientData.value.manager.surname}`
      : null));
    const newUserDisplayManager = computed(() => {
      const manager = employees.value.find(
        (employee) => employee.value === clientData.value.manager_id,
      );
      return manager && manager.label ? manager.label : null;
    });

    // Client priority
    const priorities = [
      {
        value: 0,
        color: 'secondary',
        label: 'Обычный',
      },
      {
        value: 1,
        color: 'success',
        label: 'Высокий',
      },
    ];
    const computedClientPriority = computed(
      () => priorities.find((priority) => priority.value === clientData.value.priority),
    );

    // Stats
    let incomeMonthly;
    let incomeTotal;
    if (!isNew.value) {
      incomeMonthly = computed(() => formatPrice(
        clientData.value.income_month.amount,
        {
          rate: 1,
          iso: clientData.value.income_month.currency,
        },
        0,
        'en',
        false,
        'code',
      ));
      incomeTotal = computed(() => formatPrice(
        clientData.value.income_total.amount,
        {
          rate: 1,
          iso: clientData.value.income_total.currency,
        },
        0,
        'en',
        false,
        'code',
      ));
    }

    // City
    const citiesList = ref([]);
    const cityQuery = ref('');

    const getCities = async () => {
      try {
        const res = await store.dispatch('dadata/getCities', { query: cityQuery.value });
        citiesList.value = res.map(({ value }) => ({
          label: value,
          value,
        }));
      } catch (err) {
        //
        console.error(err);
      }
    };
    watch(cityQuery, getCities);

    let citySearchTimer = null;
    const onCityInput = (val) => {
      clearTimeout(citySearchTimer);
      citySearchTimer = setTimeout(() => {
        cityQuery.value = val;
        citySearchTimer = null;
      }, 500);
    };

    const avatarUploadConfig = {
      updateHandler: (formData) => store.dispatch('clients/updateClientAvatar', { formData, itemId: clientData.value.id }),
      deleteHandler: (formData) => store.dispatch('clients/deleteClientAvatar', { formData, itemId: clientData.value.id }),
    };

    const avatarUpdated = () => {
      emit('updated');
    };

    const deleteClient = inject('deleteClient');

    return {
      t,
      isProcessing,

      errors,

      clientTypes,
      clientData,

      displayClientType,

      updateField,

      newTag,
      addTag,

      computedTags,
      getTags,

      removeTag,

      employees,
      getEmployees,
      computedEmployees,

      displayManager,
      newUserDisplayManager,
      updateClientManager,

      priorities,
      computedClientPriority,

      incomeMonthly,
      incomeTotal,

      onCityInput,
      citiesList,
      cityQuery,

      avatarUploadConfig,
      avatarUpdated,

      deleteClient,
    };
  },
};
</script>

<style lang="scss">
.client-card-limit {
  max-width: 60%;
  @media (max-width: 600px) {
    max-width: 40%;
  }
}
.client-actions-dropdown {
  position: absolute;
  right: 15px;
  top: 20px;

  &-item {
    &:hover {
      background: rgba(234, 84, 85, 0.12);
    }

    a {
      &:hover {
        background-color: unset;
      }
    }
  }

  .dropdown-toggle {
    background: transparent !important;
    padding: 0;
    border: none !important;

    &::after {
      display: none;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
}

.client {
  &__tag {
    margin: 5px 6px 0 0;
  }
  &-card {
    &__field {
      display: grid;
      grid-template-columns: 180px 1fr;
    }
    &__actions {
    }
  }
}
</style>
