export const useClient = () => {
  const getEmptyContactItem = () => ({
    name: '',
    position: '',
    phone: '',
    email: '',
  });

  const getEmptyBankDetails = () => ({
    full_name: '',
    fact_address: '',
    inn: '',
    ogrn: '',
    kpp: '',
    business_address: '',
    bank_name: '',
    bank_bik: '',
    bank_corr: '',
    bank_account: '',
    ceo: '',
    main_accountant: '',
  });

  return {
    getEmptyContactItem,
    getEmptyBankDetails,
  };
};
