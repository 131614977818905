<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        lg="6"
        md="7"
      >
        <ClientInfoCard :is-new="true" />
        <div class="d-flex mb-2">
          <b-button
            class="mr-1"
            variant="primary"
            :disabled="isCreatingClient"
            @click="createClient"
          >
            Сохранить
          </b-button>
          <b-button
            variant="outline-primary"
            @click="cancel"
          >
            Отменить
          </b-button>
        </div>
      </b-col>
      <!-- <b-col
        cols="12"
        md="6"
        xl="6"
        lg="4"
      >
        <ClientPropsCard
          :is-new="true"
          @addNewContact="addNewContact"
          @removeContact="removeContact"
        />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue';
import {
  computed, provide, ref, set,
} from '@vue/composition-api';
import { useRouter } from '@core/utils/utils';
import ClientInfoCard from '@/components/clientInfoCard/ClientInfoCard.vue';
// import ClientPropsCard from '@/components/clientPropsCard/ClientPropsCard.vue';
import { useClient } from '@/hooks/useClient';
import { getRandomString } from '@/utils/stringHelpers';
import { useStore } from '@/hooks/useStore';
import {useToast} from "@/hooks/useToast";
import {useI18n} from "@/hooks/useI18n";

export default {
  name: 'ClientAdd',
  components: {
    BButton,
    BRow,
    BCol,

    ClientInfoCard,
    // ClientPropsCard,
  },
  setup() {
    const store = useStore();
    const { router } = useRouter();
    const { t } = useI18n();
    const { dangerToast } = useToast();

    const errors = ref([]);

    const {
      getEmptyContactItem,
      getEmptyBankDetails,
    } = useClient();

    const clientData = ref({
      name: '',
      type: 'person',
      avatar: null,
      manager_id: null,
      priority: 0,
      city: '',
      tags: [],
    });
    provide('clientData', clientData);
    provide('errors', errors);

    // Contacts
    const contacts = ref([
      getEmptyContactItem(),
    ]);
    provide('contacts', contacts);
    const addNewContact = (contact) => {
      contacts.value.push({
        ...contact,
        id: getRandomString(),
      });
    };
    const removeContact = async (contactId) => {
      contacts.value = contacts.value.filter((contact) => contact.id !== contactId);
    };

    // Bank details
    const bankDetails = ref(getEmptyBankDetails());
    provide('bankDetails', bankDetails);

    const computedClientData = computed(() => ({
      ...clientData.value,
      contacts: contacts.value,
      bank_details: bankDetails.value,
    }));

    const isCreatingClient = ref(false);
    const createClient = async () => {
      errors.value = [];
      if (isCreatingClient.value) return;
      isCreatingClient.value = true;
      const { result, data } = await store.dispatch('clients/createClient', computedClientData.value);

      if (result) {
        await router.push({
          name: 'client-view',
          params: {
            clientId: data.id,
          },
        });
      } else {
        const responseErrors = Object.entries(data.errors);
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of responseErrors) {
          errors.value.push(key);
          value.forEach((error) => {
            dangerToast(
                t('errors.something-went-wrong.text'),
                error.replace(`${key}`, t(`client.validation.${key}.replace`)),
            );
          })
        }
        console.log('ERRORS', data.errors);
      }

      isCreatingClient.value = false;
    };

    const cancel = () => {
      router.push('/clients');
    };

    const setDefaults = async () => {
      const currentUser = store.state?.user?.data || {};
      const {
        id,
      } = currentUser;
      set(clientData.value, 'manager_id', id);
    };
    setDefaults();

    return {
      clientData,

      addNewContact,
      removeContact,
      errors,

      computedClientData,
      isCreatingClient,
      createClient,
      cancel,
    };
  },
};
</script>

<style lang="scss">
</style>
